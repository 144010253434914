<template>
  <div>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Location Settings
        <small>Deefine pickup and dropoff positions.</small>
      </v-stepper-step>
      <v-stepper-content step="1">
        <v-card class="mb-12">
          <v-container>
            <vuetify-google-autocomplete
              id="map1"
              :country="['ug']"
              v-bind:disabled="false"
              classname="form-control"
              prepend-inner-icon="place"
              label="Pickup Location"
              v-model="createNewRequest.pickupLocationLabel"
              outlined
              dense
              v-on:placechanged="getPickupAddressData"
              :rules="[rules.required]"
            >
            </vuetify-google-autocomplete>

            <vuetify-google-autocomplete
              id="map2"
              :country="['ug']"
              v-bind:disabled="false"
              classname="form-control"
              prepend-inner-icon="place"
              label="Package Destination"
              v-model="createNewRequest.destinationLocationLabel"
              outlined
              dense
              v-on:placechanged="getDestinationAddressData"
              :rules="[rules.required]"
            >
            </vuetify-google-autocomplete>

            <v-textarea
              v-model="createNewRequest.deliveryInstructions"
              label="Request Delivery Instructions"
              outlined
              dense
            />
          </v-container>
        </v-card>
        <v-btn class="md-1 px-6">Cancel</v-btn>
        <v-btn
          color="primary"
          @click="
            validateLocationData != false
              ? (e6 = 2)
              : displayFormError('Please fill all required Fields.')
          "
          >Continue</v-btn
        >
      </v-stepper-content>

      <!--Package Details below-->
      <v-stepper-step :complete="e6 > 2" step="3"
        >Configure Package Details.</v-stepper-step
      >
      <v-stepper-content step="2">
        <v-card class="mb-12">
          <v-container>
            <v-select
              v-model="createNewRequest.vehicleType"
              :items="getVehicleTypes"
              item-text="label"
              item-value="id"
              label="Vehicle Type"
              outlined
              dense
              @change="requestEstimatedDeliveryFee"
            >
            </v-select>

            <v-text-field
              v-model="createNewRequest.packageWeigh"
              label="Package Weigh (KG)"
              outlined
              dense
            />

            <v-row v-if="packegeAreaEnabled">
              <v-col lg="4">
                <v-text-field
                  v-model="createNewRequest.packageLength"
                  label="Package Length (CM)"
                  outlined
                  dense
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  v-model="createNewRequest.packageWidth"
                  label="Package Width (CM)"
                  outlined
                  dense
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  v-model="createNewRequest.packageHeight"
                  label="Package Height (CM)"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-textarea
              v-model="createNewRequest.packageDescription"
              label="Package Description"
              outlined
              dense
            />
          </v-container>
        </v-card>
        <v-btn class="md-1 px-6" @click="e6 = 1">Back</v-btn>
        <v-btn
          color="primary"
          @click="
            validatePakageDetails() != false
              ? (e6 = 3)
              : displayFormError('Please configure target.')
          "
          >Continue</v-btn
        >
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="2"
        >Package Receiver Information.</v-stepper-step
      >
      <v-stepper-content step="3">
        <v-card class="mb-3">
          <v-container>
            <v-text-field
              v-model="createNewRequest.recieverFullName"
              label="Receiver Full Name"
              outlined
              dense
            />

            <v-text-field
              v-model="createNewRequest.recieverPhoneNumber"
              label="Receiver Phone Number"
              outlined
              dense
            />
          </v-container>
        </v-card>
        <v-btn class="md-1 px-6" @click="e6 = 2">Back</v-btn>
        <v-btn
          color="primary"
          @click="
            validateOfferForm != false
              ? (e6 = 4)
              : displayFormError('Please provide the offer.')
          "
          >Continue</v-btn
        >
      </v-stepper-content>

      <!--Package Pickup Scheduling-->
      <v-stepper-step :complete="e6 > 4" step="4"
        >Package Pickup Scheduling.</v-stepper-step
      >
      <v-stepper-content step="4">
        <v-card color="pl-4 pr-4 pt-4 grey darken-4" class="mb-12">
          <v-container>
            <v-checkbox
              v-model="createNewRequest.asSoonAsPosible"
              label="As Soon As Posible"
            />
            <DateTimePicker
              v-model="createNewRequest.dateTime"
              label="Date And Time"
              :disabled="createNewRequest.asSoonAsPosible"
            />
          </v-container>
        </v-card>

        <v-btn class="md-1 px-6" @click="e6 = 3">Back</v-btn>
        <v-btn
          color="primary"
          @click="
            validateSchedulingForm != false
              ? (e6 = 5)
              : displayFormError('Please choose an option.')
          "
          >Continue</v-btn
        >
      </v-stepper-content>

      <v-stepper-step step="5">Consent</v-stepper-step>
      <v-stepper-content step="5">
        <v-card class="mb-12">
          <v-card-text>
            <p>
              {{
                `Estimated Distance: ${Math.round(
                  getEstimatedDeliveryFee.distance * 100
                ) / 100} KM, Estimated Fee: UGX ${Math.round(
                  getEstimatedDeliveryFee.estimatedFee * 100
                ) / 100}`
              }}. By submitting this request, we asume that you have gone
              through what you have filled, and you are aware that any mistakes
              made on creation of the request can financialy affect the company.
            </p>

            <v-checkbox
              v-model="createNewRequest.consent"
              label="I know and understand what am doing."
            />
          </v-card-text>
        </v-card>
        <v-btn class="md-1 px-6" @click="e6 = 4">Back</v-btn>
        <v-btn
          v-if="action === 'viewCampaign'"
          color="primary"
          @click="
            validateConsentForm != false
              ? updateCampaign()
              : displayFormError('Please Aggree to the terms and continue')
          "
          >Update</v-btn
        >
        <v-btn
          v-else
          color="primary"
          @click="
            validateConsentForm != false
              ? submitCampaign()
              : displayFormError('Please Aggree to the terms and continue')
          "
          >Request</v-btn
        >
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import DateTimePicker from "@/components/requests/DateTimePicker";

export default {
  template: "#createCampaign",
  components: {
    DateTimePicker,
  },
  data() {
    return {
      createNewRequest: {
        asSoonAsPosible: true,
      },
      rules: {
        required: (value) => !!value || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
      },
      // vehicleTypes: [
      //   {
      //     id: "BIKE",
      //     label: "Motor Bike",
      //   },
      //   {
      //     id: "CAB",
      //     label: "CAB",
      //   },
      //   {
      //     id: "SMALL_TRACK",
      //     label: "Small Tack",
      //   },
      //   {
      //     id: "BIG_TRACK",
      //     label: "Big Tack",
      //   },
      //   {
      //     id: "LORY",
      //     label: "LORY",
      //   },
      // ],
      e6: 1,
      action: "",
      packegeAreaEnabled: false,
    };
  },

  created() {
    if (this.$route.query.action === "viewCampaign") {
      this.action = this.$route.query.action;
      this.$store.dispatch(
        "loadMarketingCampaign",
        this.$route.query.campaignID
      );
    }
    this.$store.dispatch("fetchVehicleTypes");
  },

  computed: {
    validateLocationData() {
      let formStatus = true;
      if (
        this.createNewRequest.pickupLocationLabel === undefined ||
        this.createNewRequest.pickupLocationLabel === null ||
        this.createNewRequest.pickupLocationLabel === ""
      ) {
        formStatus = false;
      }
      if (
        this.createNewRequest.destinationLocationLabel === undefined ||
        this.createNewRequest.destinationLocationLabel === null ||
        this.createNewRequest.destinationLocationLabel === ""
      ) {
        formStatus = false;
      }
      return formStatus;
    },

    validateOfferForm() {
      let formStatus = true;
      return formStatus;
    },

    validateSchedulingForm() {
      let formStatus = true;
      if (
        this.createNewRequest.asSoonAsPosible === undefined ||
        this.createNewRequest.asSoonAsPosible === null ||
        this.createNewRequest.asSoonAsPosible === ""
      ) {
        formStatus = false;
      }
      return formStatus;
    },

    validateConsentForm() {
      let formStatus = true;
      if (
        this.createNewRequest.consent === undefined ||
        this.createNewRequest.consent === "" ||
        this.createNewRequest.consent === null
      ) {
        formStatus = false;
      }
      return formStatus;
    },
    ...mapState(["vehicleTypes", "estimatedDeliveryFee"]),
    ...mapGetters([
      "messageAlert",
      "getVehicleTypes",
      "getEstimatedDeliveryFee",
    ]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$router.push({ path: "/requests", query: {} });
      }
    },
  },

  methods: {
    validatePakageDetails() {
      const context = this;
      let goOn = true;

      if (
        context.createNewRequest.vehicleType === undefined ||
        context.createNewRequest.vehicleType === null ||
        context.createNewRequest.vehicleType === ""
      ) {
        goOn = false;
      }

      if (
        context.createNewRequest.packageWeigh === undefined ||
        context.createNewRequest.packageWeigh === null ||
        context.createNewRequest.packageWeigh === ""
      ) {
        goOn = false;
      }

      return goOn;
    },

    displayFormError(message) {
      Vue.notify({
        group: "foo",
        title: "Error",
        text: message,
      });
    },

    submitCampaign(e) {
      const context = this;
      context.$store.dispatch("requestDeliveryGuy", context.createNewRequest);
    },

    updateCampaign(e) {
      const context = this;
      context.$store.dispatch("updateRequest", context.createNewRequest);
    },

    getPickupAddressData: function(addressData, placeResultData, id) {
      this.createNewRequest.pickupLocation = addressData;
    },

    getDestinationAddressData: function(addressData, placeResultData, id) {
      this.createNewRequest.destinationLocation = addressData;
    },

    requestEstimatedDeliveryFee: function() {
      const context = this;
      context.$store.dispatch(
        "requestEstimatedDeliveryFee",
        context.createNewRequest
      );
    },
  },
};
</script>
